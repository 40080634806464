import React from "react"

// Assets
import PhoneIcon from "../../images/icons/phone.inline.svg"
import EditIcon from "../../images/icons/edit-custom.inline.svg"
import BulbIcon from "../../images/icons/bulb.inline.svg"
// Components
import Card from "./card"
import Icon from "./icon"

const Benefits = () => {
  return (
    <div className="max-w-screen-xl mx-auto grid grid-cols-1 sm:grid-cols-3 gap-x-8 gap-y-6 py-10 sm:py-16">
      <Card>
        <Icon>
          <PhoneIcon />
        </Icon>
        <div className="text-center">
          <p className="text-black font-medium text-xl mb-2">
            Izstopajoč Instagram Feed
          </p>
          <p>
            Izbiraj med različnimi preset paketi in ustvari svoj perfect
            "scroll-stopping" Instagram feed.
          </p>
        </div>
      </Card>
      <Card>
        <Icon>
          <EditIcon />
        </Icon>
        <div className="text-center">
          <p className="text-black font-medium text-xl mb-2">
            Popolnoma Prilagodljivo
          </p>
          <p>
            Vsi preset paketi so narejeni tako da jih lahko prilagodiš kakor
            želiš. Svetloba, toni, barve, čisto vse.
          </p>
        </div>
      </Card>
      <Card>
        <Icon>
          <BulbIcon />
        </Icon>
        <div className="text-center">
          <p className="text-black font-medium text-xl mb-2">
            Preprosta Uporaba
          </p>
          <p>
            Obljubim ti, da je namestitev in uporaba presetov zelo enostavna. Z
            enim klikom lahko ustvariš kar želiš.
          </p>
        </div>
      </Card>
    </div>
  )
}

export default Benefits
