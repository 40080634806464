import React from "react"

const Text = () => {
  return (
    <div className="mb-5">
      <h1
        className="text-3xl md:text-5xl font-bold mb-3"
        style={{ lineHeight: 1.15 }}
      >
        Brezplačen Lightroom Mobile Preset Paket?{" "}
        <span role="img" aria-label="Stars in eyes emoji">
          🤩
        </span>
      </h1>
      <p>
        Prejmi <strong>8 brezplačnih Lightroom Mobile presetov</strong> in začni{" "}
        ustvarjati profesionalne fotke z enim klikom!{" "}
        <span role="img" aria-label="heart emoji">
          ❤️
        </span>
      </p>
    </div>
  )
}

export default Text
