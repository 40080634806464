import React, { useContext } from "react"

// Context
import { StoreContext } from "../../context/store-context"

// Components
import DotFlashing from "../loader/dotFlashing"

const Button = ({ storeFrontId, freebieUrl }) => {
  const { loading, addVariantToCart } = useContext(StoreContext)

  const addToCart = e => {
    e.preventDefault()
    addVariantToCart(storeFrontId, 1)
  }

  return freebieUrl ? (
    <a
      href={freebieUrl}
      rel="noreferrer"
      target="__blank"
      className="block text-center py-4 w-full h-14 mt-5 mb-3 md:w-64 px-12 font-semibold transition duration-200 ease-in-out bg-primary-80 hover:bg-primary-110 shadow"
    >
      Prejmi svoje Presete!
    </a>
  ) : (
    <button
      disabled={loading}
      onClick={addToCart}
      className=" py-4 w-full h-14 mt-5 mb-3 md:w-64 px-12 font-semibold transition duration-200 ease-in-out bg-primary-80 hover:bg-primary-110 shadow"
    >
      {loading ? <DotFlashing /> : "Dodaj v Košarico"}
    </button>
  )
}

export default Button
