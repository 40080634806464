import React from "react"

const DotFlashing = () => {
  return (
    <div className="flex  items-center justify-center">
      <div className="snippet flex" data-title=".dot-flashing">
        <div className="stage">
          <div className="dot-flashing"></div>
        </div>
      </div>
    </div>
  )
}

export default DotFlashing
