import React from "react"
import SwiperCore, { Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import "swiper/components/pagination/pagination.min.css"

// Components
import Quote from "../quote/index"

SwiperCore.use([Pagination, Autoplay])

const QuotesCarousel = ({ items }) => {
  const settings = {
    navigation: false,
    pagination: true,
    grabCursor: true,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  }

  return (
    <div className="pt-10 pb-8">
      <Swiper {...settings}>
        <SwiperSlide>
          <Quote
            text="Lili priseti so zmeraj tako dobro narejeni! Podajo se na vsako fotografijo neglede na to kaksna svetloba je ali v katerem kraju slikas😊 polek tega pa so tako enostavni za uporabo in naredijo nase fotografije v sekundi pravljicne!✨ Bravo Lili💛"
            person="Tea"
            instaHandle="teamonorri"
          />
        </SwiperSlide>
        <SwiperSlide>
          <Quote
            text="Preseti so res neverjetni. Iz slike z zelo slabo svetlobo v samo enem kliku nardijo sliko, ki jo lahko takoj objaviš! Priporočam vsem☺️"
            person="Eva"
            instaHandle="jug_eva"
          />
        </SwiperSlide>
        <SwiperSlide>
          <Quote
            text="Res so lepi in vse slike polepšajo rada jih imam ker so edinstveni in za njih ne rabim odstet veliko denarja in je enostavno in se po vrhu lepo ❤️❤️"
            person="Ana"
            instaHandle="ana_potocnik"
          />
        </SwiperSlide>
        <SwiperSlide>
          <Quote
            text="Tok sem naudušena, da sem mogla presetke takoj sprobat🥰🙈👍🏼❤️‍🔥 pomoje bom kar cel dan preživela na telefonu in urejala slike s telimi bombastičnimi preseti!☺️☺️"
            person="Taja"
            instaHandle="tajakavcic"
          />
        </SwiperSlide>
        <SwiperSlide>
          <Quote
            text="Krasni preseti, redko potrebno, a enostavno prilagajanje nastavitev, kupila sem ze 3 bundle in za nobenega mi ni žal 🥰🥰"
            person="Tinkara"
            instaHandle="tinkaranared"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default QuotesCarousel
