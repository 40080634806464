import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const FeedMakeover = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 items-center">
      <div>
        <p className="uppercase mb-1 text-gray-700 text-sm font-medium">
          Lightroom Preseti
        </p>
        <h3 className="font-semibold text-2xl mb-3">
          Preobrazi svoj Instagram feed
        </h3>
        <p className="mb-5">
          Pobrskaj med različnimi stili Presetov in začni kreirati svoj
          Instagram feed v manj kot minuti. Estetika zagotovljena.{" "}
          <span role="img" aria-label="Smiley face with hearts in his eyes.">
            😍
          </span>
        </p>
        <Link to="/presets">
          <button className="max-w-max py-4 px-20 font-semibold transition duration-200 ease-in-out bg-primary-80 hover:bg-primary-110 shadow">
            Poglej Presete
          </button>
        </Link>
      </div>
      <StaticImage
        src="../../images/homepage/feed.jpeg"
        alt="Grid of pictures"
        placeholder="blurred"
        loading="lazy"
        style={{ maxWidth: 650 }}
        className="justify-items-center lg:justify-self-end lg:col-span-2"
      />
    </div>
  )
}

export default FeedMakeover
