import React from "react"
import { StaticImage } from "gatsby-plugin-image"
// Components
import Text from "./text"
import Button from "../../productInfo/button"

const Hero = () => {
  return (
    <section
      style={{
        backgroundImage: "linear-gradient(180deg, #fff 10%, #eef0f7)",
        backgroundRepeat: "repeat-x",
      }}
    >
      <div className="max-w-screen-lg mx-auto items-center px-4 grid gap-y-5 grid-cols-1 md:grid-cols-2">
        <div className="px-5 pt-10 md:py-16">
          <Text />
          <Button freebieUrl="https://influspace.agency/creators/preset-pack" />
        </div>
        <div className="px-5 grid md:pt-10">
          <div className="w-full self-end flex items-center justify-center">
            <StaticImage
              src="../../../images/homepage/lr-presets-ita-freebie.png"
              alt="Hero Image"
              placeholder="blurred"
              height={468}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
