import React from "react"
import Stars from "../reviews/stars"

const Quote = ({ text, person, instaHandle }) => {
  return (
    <div className="max-w-screen-sm mx-auto text-center grid grid-cols-1 gap-4 pb-12 px-8">
      <div className="mx-auto">
        <Stars rating={5} />
      </div>
      <p className="italic text-xl sm:text-2xl">{text}</p>
      <hr
        className="border border-primary-110 mx-auto w-full"
        style={{ maxWidth: 80 }}
      />
      <a
        href={`https://instagram.com/${instaHandle}`}
        target="_blank"
        rel="noreferrer"
      >
        {person}
      </a>
    </div>
  )
}

export default Quote
