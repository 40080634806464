import React from "react"
import loadable from "@loadable/component"

import Form from "./form"
const BenefitsMobile = loadable(() => import("./benefits/benefitsMobile"))
const BenefitsDesktop = loadable(() => import("./benefits/benefitsDesktop"))

const Hero = ({ includeBottom }) => {
  return (
    <section>
      <div className="max-w-screen-2xl mx-auto relative">
        <Form />
      </div>
      {includeBottom && (
        <div className="max-w-screen-xl mx-auto px-0 bg-primary-30 md:px-4 md:bg-white">
          <BenefitsDesktop />
          <BenefitsMobile />
        </div>
      )}
    </section>
  )
}

export default Hero
