import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"

// Components
import DynamicWrapper from "../components/dynamicWrapper"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import OneClickEditing from "../components/oneClickEditing"
import QuotesCarousel from "../components/quotesCarousel"
import WhatArePresets from "../components/whatArePresets"
// import Newsletter from "../components/newsletter"
import Benefits from "../components/benefits"
import FeedMakeover from "../components/feedMakeover"
import { SectionHeading } from "../components/headings"
const ComparisonCarousel = loadable(() =>
  import("../components/comparisonSlider")
)

const HomePage = ({ location }) => {
  // const { discountedProds, bestSellingProds } = data

  return (
    <Layout path={location.pathname}>
      <Seo
        title="Mobile Lightroom Presets"
        lang="sl"
        description="Mobile Lightroom Presets - Urejaj slike z enim klikom - Top kvaliteta in doživljenski dostop. 24/7 podpora."
      />
      <Hero langKey={location.pathname} includeBottom="true" />
      <section className="max-w-screen-xl mx-auto px-4 py-10 sm:py-16">
        <FeedMakeover />
      </section>
      <section className="max-w-screen-xl mx-auto px-4 pt-6 pb-10 sm:pb-16">
        <SectionHeading title="heading3" />
        <DynamicWrapper>
          <ComparisonCarousel location={location} />
        </DynamicWrapper>
      </section>
      <section className="bg-primary-30 px-4 mt-6 mb-10 sm:mb-16">
        <QuotesCarousel />
      </section>
      <section className="max-w-screen-xl mx-auto px-4 pt-6 pb-10 sm:pb-16">
        <OneClickEditing langKey={location.pathname} />
      </section>
      <section className="bg-primary-30 px-4 mt-6 mb-10 sm:mb-16">
        <Benefits />
      </section>
      <section className="max-w-screen-xl mx-auto px-4 pt-6 pb-10 sm:pb-16">
        <WhatArePresets langKey={location.pathname} />
      </section>
      <Hero langKey={location.pathname} />
      {/* <section
        className="bg-primary-30 px-4 pt-6 pb-10 sm:pb-16"
        id="newsletter"
      >
        <Newsletter />
      </section> */}
    </Layout>
  )
}

export const query = graphql`
  query pageQuerySl {
    bestSellingProds: allShopifyProduct(
      filter: {
        collections: { elemMatch: { title: { eq: "Home Page Best Sellers" } } }
      }
      limit: 8
      sort: { fields: variants___price, order: ASC }
    ) {
      edges {
        node {
          id
          title
          featuredImage {
            id
            altText
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              layout: CONSTRAINED
            )
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          handle
          variants {
            availableForSale
            storefrontId
            title
            price
            compareAtPrice
            selectedOptions {
              name
              value
            }
          }
          tags
          storefrontId
          options {
            name
            values
            id
          }
          collections {
            title
          }
        }
      }
    }
    discountedProds: allShopifyProduct(
      filter: {
        collections: { elemMatch: { title: { eq: "Home Page Discounts" } } }
      }
      limit: 8
      sort: { fields: variants___price, order: DESC }
    ) {
      edges {
        node {
          id
          title
          featuredImage {
            id
            altText
            gatsbyImageData(
              placeholder: BLURRED
              width: 640
              formats: [AUTO, WEBP]
              layout: CONSTRAINED
            )
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          handle
          variants {
            availableForSale
            storefrontId
            title
            price
            compareAtPrice
            selectedOptions {
              name
              value
            }
          }
          tags
          storefrontId
          featuredImage {
            id
            originalSrc
          }
          options {
            name
            values
            id
          }
          collections {
            title
          }
        }
      }
    }
  }
`

export default HomePage
