import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Image = () => {
  return (
    <StaticImage
      src="../../images/homepage/what-are-lightroom-presets.jpeg"
      alt="Image of a person"
      placeholder="blurred"
      loading="lazy"
      style={{ maxWidth: 450 }}
      className="justify-self-center order-2 md:order-1"
    />
  )
}

const WhatArePresets = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
      <Image />
      <div className="md:p-12 md:bg-primary-30 order-1 md:order-2 relative">
        <span
          className="hidden md:block absolute -right-3 -top-3 text-4xl transform rotate-12"
          role="img"
          aria-label="Camera"
        >
          📸
        </span>
        <h3 className="font-semibold text-2xl mb-4">
          Kaj so Lightroom Presets?
        </h3>
        <p className="mb-6">
          Lightroom presets so napredni filtri za fotografije, ki avtomatsko
          uredijo tvojo sliko z enim klikom. Za uporabo pa ne potrebuješ čisto
          nobenega predznanja.
        </p>
        <Link to="/presets">
          <button className="max-w-max py-4 px-20 font-semibold transition duration-200 ease-in-out bg-primary-80 hover:bg-primary-110 shadow">
            Poglej Presete
          </button>
        </Link>
      </div>
    </div>
  )
}

export default WhatArePresets
