import React from "react"

// Assets
import StarIcon from "../../images/icons/star.inline.svg"

const Stars = ({ rating }) => {
  let array = new Array(rating).fill(0)
  return (
    <div className="flex items-center">
      {array.map((num, index) => (
        <StarIcon key={index} />
      ))}
    </div>
  )
}

export default Stars
