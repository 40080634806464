import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const OneClickEditing = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
      <div className="md:p-12 md:bg-primary-30 relative">
        <span
          className="hidden md:block absolute -top-4 -left-4 text-5xl transform -rotate-12"
          role="img"
          aria-label="Heart"
        >
          💞
        </span>
        <p className="uppercase mb-1 text-gray-700 text-sm font-medium">
          Trije preprosti koraki
        </p>
        <h3 className="font-semibold text-2xl mb-4">
          Kako uporabljati Lightroom Presete?
        </h3>
        <ul className="list-decimal pl-5 mb-6">
          <li className="mb-1">Naredi sliko z telefonom ali kamero.</li>
          <li className="mb-1">
            Uporabi <strong>brezplačno</strong> Lightroom aplikacijo in dodaj
            preset.
          </li>
          <li className="mb-1">
            To je vse! Tvoja slika je ready!{" "}
            <span role="img" aria-label="100">
              💯
            </span>
          </li>
        </ul>
        <Link to="/presets">
          <button className="max-w-max py-4 px-20 font-semibold transition duration-200 ease-in-out bg-primary-80 hover:bg-primary-110 shadow">
            Poglej Presete
          </button>
        </Link>
      </div>
      <StaticImage
        src="../../images/homepage/lightroom-presets-phone-mockup.jpeg"
        alt="Phone mockup"
        placeholder="blurred"
        loading="lazy"
        style={{ maxWidth: 450 }}
        className="justify-self-center"
      />
    </div>
  )
}

export default OneClickEditing
